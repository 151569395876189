.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tab-bg {
  background: #262125;
  color: white;
  position: relative;
}

.tab-icon.btn.btn-link.collapsed i {
  transform: rotate(0deg);
}

.tab-icon.btn.btn-link i {
  transform: rotate(180deg);
  transition: 0.5s;
}

.text-control {
  text-align: center;
  font-size: 15px;
  margin: auto;
}

.icon-large {
  font-size: 25px;
  padding: auto;
}

.tab-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 40px;
}

.tab-pointer:hover {
  cursor: pointer;
}

.tab-shadow-control {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.active-badge {
  border-radius: 0.25rem;
  font-size: 0.6rem;
  font-weight: initial;
  line-height: 1;
  padding: 0.5rem 0.7rem;
  font-family: $type1;
  font-weight: $font-weight-medium;
  &.badge-pill {
    border-radius: 10rem;
  }
}

.spacing {
  margin-top: 10px !important;
}

.reset-btn {
  background-color: yellow;
  color: #ddbc6b;
  transition: transform 0.2s;
}
.reset-btn:hover {
  color: rgb(0, 91, 62);
  transform: scale(1.5);
}

button.btn.btn-link.collapsed i {
  transform: rotate(0deg);
}

button.btn.btn-link i {
  transform: rotate(180deg);
  transition: 0.5s;
}

.cursor-control {
  cursor: pointer;
  transition: transform 0.3s;
  transform: rotate(0deg);
}
.cursor-control:hover {
  transform: rotate(180deg);
}

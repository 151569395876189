/* operation css */

.card-left {
  border-right: 5px solid #ddbc6b;
  border-left: 5px solid #ddbc6b;
}

.card-right {
  border-right: 5px solid #ddbc6b;
}

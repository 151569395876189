.not-found {
  color: #ddbc6b;
  font-size: 150px;
  margin: auto;
}

.not-found-404 {
  color: #2a2a2a !important;
  font-weight: bold !important ;
  font-size: 20px !important;
}
@media screen AND (max-width: 585px) {
  .not-found {
    font-size: 80px !important;
  }
}

.forget-buttons {
  background-color: #ddbc6b;
  color: white;
  border: none;
  outline: none;
  border-radius: 25px;
  padding: 10px 69px;
  font-size: 0.8em;
  font-weight: 500;
}

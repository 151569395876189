.App {
  height: 100vh;
  display: flex;
  color: white;
}

.wrapper {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.appAside-login {
  padding: auto;
  margin-top: 15% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  height: 100vh;
  display: flex;
  color: white;
}

.appAside-login {
  width: 100%;
  background-color: transparent;
  display: flex;
}

.logo-footer {
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
}
.logo-footer:hover {
  color: white;
  font-weight: bold;
  font-size: 22px;
}

.appForm {
  width: 100%;
  background-color: #12130f; 
  overflow: auto;
  height: 100vh;
}

.pageSwitcher {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10%;
}

.pageSwitcherItem {
  background-color: #40434e;
  color: #9da6b1;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 0.9em;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
}

.pageSwitcherItem-active {
  background-color: #ddbc6b;
  color: white;
}

.pageSwitcherItem:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.pageSwitcherItem:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.formCenter {
  margin-top: 20px;
}

.formTitle {
  color: #707c8b;
  font-weight: 300;
  margin-bottom: 50px;
}

.formTitleLink {
  color: #707c8b;
  text-decoration: none;
  display: inline-block;
  font-size: 1.7em;
  margin: 0 10px;
  padding-bottom: 5px;
}

.formTitleLink:first-child {
  margin-left: 0;
}

.formTitleLink-active {
  color: white;
  border-bottom: 1px solid #ddbc6b;
}

.formField {
  margin-bottom: 40px;
}
.btn-field {
  display: flex;
  justify-content: start;
  justify-content: space-around;
  flex-wrap: wrap;
  /* justify-content: space-between !important; */
}

.formFieldLabel {
  display: block;
  text-transform: uppercase;
  font-size: 0.9em;
  color: white;
}
.formFieldInput {
  width: 100% !important;
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 1em;
  font-weight: 300;
  margin-top: 10px;
  height: 30px !important;
}

.formFieldInput::placeholder {
  color: #616e7f;
}

.formFieldButton {
  background-color: #ddbc6b;
  color: #000;
  border: none;
  outline: none;
  border-radius: 25px;
  padding: 10px 50px;
  font-size: 0.8em;
  font-weight: 500;
}

.formFieldLink {
  color: #66707d;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1.5px solid #ddbc6b;
  padding-bottom: 5px;
}

.formFieldCheckboxLabel {
  color: #646f7d;
  font-size: 0.9em;
}

.formFieldCheckbox {
  position: relative;
  top: 1.5px;
}

.formFieldTermsLink {
  color: white;
  border-bottom: 1px solid #ddbc6b;
  text-decoration: none;
  display: inline-block;
  padding-bottom: 2px;
  margin-left: 5px;
}

.socialMediaButtons {
  padding: 10px 100px 10px 0px;
}

.main-cont .container-fluid {
  padding: 0;
}

.video-play video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}

.lay-out {
  position: relative;
  z-index: 2;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
.lay-out .formFieldLabel {
  color: #000;
  font-weight: 600;
}

.top-logo img {
  max-width: 180px;
  margin: 0 auto;
  display: block;
}
.lay-out .formCenter {
  border-radius: 25px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 19px #00000040;
}

@media screen and (max-width: 550px) {
  .title-signup {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 586px) {
  .login-btn-form {
    border-radius: 30px;
    /* margin-bottom: 20px; */
    padding: 10px 35px;
    margin: 10px !important;
  }
  .login-btn-css {
    margin-right: 20px;
  }
}

@media screen and (max-width: 783px) {
  .pageSwitcher {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 410px) {
  .login-btn-responsive {
    display: flex;
    /* margin: auto; */
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 789px) {
  .appForm {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .appAside-login {
    display: none;
  }
}
@media screen and (max-width: 695px) {
  .appForm {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.password-eye-btn {
  margin-top: auto;
  height: 30px !important;
  padding-bottom: -10px;
  border-radius: 0px;
}
.password-input-width {
  width: 100% !important;
}
.forget-password-link {
  color: #000;
  font-size: 14px;
  margin: 10px 0 5px;
}
.forget-password-link:hover {
  color: white !important;
}
.footoer-logo {
  height: 150px;
  width: 300px;

}
.footer-login {
  display: flex;
  justify-content: center;
  margin-top: 50% !important;
}

.login-btn-css {
  margin-bottom: 15px !important;
}



@media only screen and (min-width: 576px) and (max-width: 1220px) {
  .login-btn-responsive {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

@media only screen and (min-width: 411px) and (max-width: 424px) {
  .login-btn-responsive {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.login-btn-css:hover {
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px !important;
  text-decoration: none !important ;
  color: white !important;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.1);
}
